import i18n from "@/i18n";
import { markRaw, toRaw } from "vue";
import {
  Plus,
  Delete,
  BottomLeft,
  TopRight,
  Search,
  Download,
  Edit,
} from "@element-plus/icons-vue";
import api from "@/api";
import moment from "moment";
import { getUploadUrl } from "../../utils/tools";
const { t } = i18n.global;
export default {
  tableCols: [
    {
      name: t("insurances.iPlate"),
      filed: "vPlate",
    },
    {
      name: t("insurances.iInsName"),
      filed: "iInsName",
    },
    {
      name: t("insurances.iAgentName"),
      filed: "iAgentName",
    },
    {
      name: t("insurances.iContactTel"),
      filed: "iContactTel",
    },
    {
      name: t("insurances.iInsType"),
      filed: "iInsType",
    },
    {
      name: t("insurances.iPolicyNo"),
      filed: "iPolicyNo",
    },
    {
      name: t("insurances.iEffectiveDate"),
      filed: "iEffectiveDate",
    },
    // {
    //   name: t("insurances.iPreWarnDays"),
    //   filed: "iPreWarnDays",
    // },
    // {
    //   name: t("insurances.iPayablePrice"),
    //   filed: "iPayablePrice",
    // },
    // {
    //   name: t("insurances.iDiscount"),
    //   filed: "iDiscount",
    // },
    // {
    //   name: t("insurances.iPaidPrice"),
    //   filed: "iPaidPrice",
    // },
    {
      name: t("insurances.iLastUpdated"),
      filed: "iLastUpdated",
    },
  ],
  tableOps: [
    {
      opType: "btn",
      icon: markRaw(Edit),
      name: t("commKey.Edit"),
      type: "",
      click: (row, callback) => {
        api.sys_insurances_row({ id: row.id }).then((res) => {
          // console.log(res.data.row, "11");
          let _row = res.data.row;
          _row.iPlate = "c_" + _row.iVehicleID;
          callback({ type: "edit", row: _row });
        });
      },
    },
    {
      opType: "del",
      icon: markRaw(Delete),
      name: t("commKey.Delete"),
      type: "danger",
      click: (row, callback) => {
        callback({ type: "delete", row: row });
        // activeKey.value = "test";
      },
    },
  ],
  searchCols: [
    {
      type: "ipt",
      title: t("commKey.keyname"),
      key: "keyname",
      val: "",
    },

    {
      type: "btn",
      btnType: "success",
      icon: markRaw(Search),
      title: t("commKey.Search"),
      click: (callback) => {
        callback({ type: "search" });
      },
    },
    {
      type: "btn",
      btnType: "primary",
      title: t("commKey.Add"),
      icon: markRaw(Plus),
      click: (callback) => {
        callback({ type: "add", row: { id: 0 } });
      },
    },
  ],
  formCols: [
    {
      type: "tsel",
      label: t("insurances.iPlate"),
      field: "iPlate",
      placeholder: t("insurances.iPlate"),
      val: "",
      required: true,
      data: [],
    },
    {
      type: "ipt",
      label: t("insurances.iInsName"),
      field: "iInsName",
      placeholder: t("insurances.iInsName"),
      val: "",
      required: true,
    },
    {
      type: "ipt",
      label: t("insurances.iAgentName"),
      field: "iAgentName",
      placeholder: t("insurances.iAgentName"),
      val: "",
      required: true,
    },
    {
      type: "ipt",
      label: t("insurances.iContactTel"),
      field: "iContactTel",
      placeholder: t("insurances.iContactTel"),
      val: "",
      required: true,
    },
    {
      type: "ipt",
      label: t("insurances.iInsType"),
      field: "iInsType",
      placeholder: t("insurances.iInsType"),
      val: "",
    },
    {
      type: "ipt",
      label: t("insurances.iPolicyNo"),
      field: "iPolicyNo",
      placeholder: t("insurances.iPolicyNo"),
      val: "",
    },
    {
      type: "datetime",
      label: t("insurances.iEffectiveDate"),
      field: "iEffectiveDate",
      placeholder: t("insurances.iEffectiveDate"),
      val: moment().format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      type: "datetime",
      label: t("insurances.iExpirationDate"),
      field: "iExpirationDate",
      placeholder: t("insurances.iExpirationDate"),
      val: moment().add(1, "years").format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      type: "ipt",
      label: t("insurances.iPreWarnDays"),
      field: "iPreWarnDays",
      placeholder: t("insurances.iPreWarnDays"),
      val: 30,
    },
    {
      type: "ipt",
      label: t("insurances.iPayablePrice"),
      field: "iPayablePrice",
      placeholder: t("insurances.iPayablePrice"),
      val: "",
    },
    {
      type: "ipt",
      label: t("insurances.iDiscount"),
      field: "iDiscount",
      placeholder: t("insurances.iDiscount"),
      val: "",
    },
    {
      type: "ipt",
      label: t("insurances.iPaidPrice"),
      field: "iPaidPrice",
      placeholder: t("insurances.iPaidPrice"),
      val: "",
    },
    {
      type: "ipt",
      label: t("insurances.iInsState"),
      field: "iInsState",
      placeholder: t("insurances.iInsState"),
      val: "",
    },
  ],
};
