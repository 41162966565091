<template>
  <div class="gps-cars">
    <view-grid
      :tableCols="tableCols"
      :tableOps="tableOps"
      :query="query"
      :tableData="tableData"
      :total="total"
      :searchCols="searchCols"
      @callbackEvent="callbackEvent"
      @handleCurrentChange="handleCurrentChange"
    ></view-grid>
    <component
      @saveEvent="saveEvent"
      ref="refAnyCom"
      :formArray="formArray"
      :is="currentCopmonent"
      :row="curr_row"
      :dialogTitle="t('insurances.Title')"
      :dialogWith="50"
    />
  </div>
</template>

<script setup>
import ViewGrid from "./components/ViewGrid.vue";
import {
  ref,
  defineAsyncComponent,
  watch,
  getCurrentInstance,
  shallowRef,
  reactive,
  onMounted,
  // markRaw,
  // toRef,
} from "vue";
// import {
//   Plus,
//   Delete,
//   BottomLeft,
//   TopRight,
//   Search,
//   Download,
//   Edit,
// } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import cars from "./InsurancesData";
import { ElNotification } from "element-plus";
const { t } = useI18n();
const refAnyCom = ref();
let { proxy } = getCurrentInstance();

//动态组件
const activeKey = ref("GpsForm");
let currentCopmonent = shallowRef({});
currentCopmonent.value = defineAsyncComponent(() =>
  import(`./components/${activeKey.value}.vue`)
);
watch(
  () => activeKey.value,
  () => {
    currentCopmonent.value = defineAsyncComponent(() =>
      import(`./components/${activeKey.value}.vue`)
    );
  }
);

//表格显示的列
const tableCols = ref(cars.tableCols);
//表格操作列
const tableOps = ref(cars.tableOps);
//表单操作
const formArray = reactive(cars.formCols);
const row = {};
const curr_row = reactive(row);

const total = ref(0);

const handleSizeChange = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChange = (val) => {
  query.page = val;
  getList();
};

//查询列
const searchCols = ref(cars.searchCols);

const tableData = ref([]);
const query = reactive({ rp: 25, page: 1 });

const getList = () => {
  proxy.$api.sys_insurances_grid(query).then((res) => {
    tableData.value = res.data.data;
    total.value = res.data.pagination.totalRecords;
  });
  // proxy.$api.item_get_company({}).then((res) => {
  //   let ay = res.data.data.map((it) => {
  //     it.name = it.cName;
  //     it.value = it.id;
  //     return it;
  //   });
  //   formArray[2].data = ay;
  // });
};
const delChannel = (dataList) => {
  dataList.forEach((it) => {
    if (it.nodetype == 2) {
      it.children = [];
    } else {
      if (it.children && it.children.length > 0) {
        delChannel(it.children);
      }
    }
  });
};

const init = () => {
  proxy.$api.tree_deptTree_Tree({}).then((res) => {
    // formArray[0].data = res.data.resultData;
    let treeData = res.data.resultData;
    delChannel(treeData);
    // console.log(treeData);
    formArray[0].data = treeData;
  });
  // proxy.$api.sys_get_bases({ flag: ["cllx", "sblx"] }).then((res) => {
  //   formArray[1].data = res.data.cllx;
  //   formArray[8].data = res.data.sblx;
  // });
  // let ay_channel = [];
  // for (let i = 0; i < 17; i++) {
  //   ay_channel.push({
  //     name: i.toString(),
  //     value: i.toString(),
  //   });
  // }
  // formArray[10].data = ay_channel;
  // //查询初始化
  proxy.$api.sys_init_datas({}).then((res) => {
    if (res.data.companys.length > 0) {
      let ay_companys = res.data.companys.map((it) => {
        return {
          name: it.cName,
          value: it.id,
        };
      });
      // console.log(searchCols);
      formArray[0].data = ay_companys;
    }
  });
};

onMounted(() => {
  getList();
  init();
});

const callbackEvent = (obj) => {
  switch (obj.type) {
    case "add":
    case "edit":
      if (obj.row) {
        Object.assign(curr_row, obj.row);
      }
      // activeShow.value = true;
      activeKey.value = "GpsForm";
      refAnyCom.value.dialogVisible = true;
      break;
    case "delete":
      proxy.$api
        .sys_insurances_delete({ ids: obj.row.id, t: 1 })
        .then((res) => {
          if (res.data.result == 1) {
            // proxy.$api
            //   .sys_orderdata_save({ ids: obj.row.id, t: 1 })
            //   .then((res_res) => {});
            ElNotification({
              title: t("commKey.MSG_TITLE"),
              message: t("commKey.DELETESUCCESS"),
              duration: 2000,
            });
          } else {
            ElNotification({
              title: t("commKey.MSG_TITLE"),
              message: t("commKey.DELETEFAIL"),
              duration: 2000,
            });
          }
          getList();
        });
      break;

    case "search":
      Object.assign(query, obj.search);
      getList();
      break;
  }
};
const saveEvent = (temp) => {
  temp.iVehicleID = temp.iPlate.split("_")[1];

  proxy.$api.sys_insurances_save(temp).then((res) => {
    ElNotification({
      title: t("commKey.MSG_TITLE"),
      message: t("commKey.MSG_" + res.data.msg_type),
      duration: 2000,
    });
    if (res.data.msg_type == 1 || res.data.msg_type == 2) {
      refAnyCom.value.dialogVisible = false;
      getList();
    }
  });
};
</script>

<style lang="scss" scoped></style>
